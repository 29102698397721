<template>
<div>
  <div id="sigle_add_robot">
    <div style="color:black;text-align: left;">
    <!-- <strong>机器人分类创建</strong> -->
    </div>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="2">
        <div class="grid-content bg-purple" style="background:transparent"></div>
      </el-col>
      <el-col :span="19">

        <div class="grid-content bg-purple-light" style="background:transparent">
          <el-form
            :model="ruleForm"
            status-icon
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            :rules="rules"
          >

            <table border="0">
              <!-- <th colspan="2" style="color:#C76362">机器人信息增加</th> -->
              <tr>
                <td>
<!--                  <div id="bitian">*</div>-->
                  <el-form-item   label="分类名称:">
                    <el-input v-model="ruleForm.robot_type"  placeholder="请输入分类名称"></el-input>
                  </el-form-item>
                </td>
                <td>
<!--                  <el-form-item label="机器人路径" prop="robot_path">-->
<!--                    <el-input v-model="ruleForm.robot_path"></el-input>-->
<!--                  </el-form-item>-->
                </td>
              </tr>
              <tr>
                <!-- <td>
                  <el-form-item label="机器人区域" prop="robot_area">
                    <el-input v-model="ruleForm.robot_area"></el-input>
                  </el-form-item>
                </td> -->

                <td>
                  <el-form-item label="分类说明:" prop="robot_name" >
                    <el-input v-model="ruleForm.robot_name" type="textarea" :rows="2" placeholder="请输入分类说明50个字以"></el-input>
<!--                    <input type="text"></input>-->
                  </el-form-item>
                </td>
                <td>
                </td>
              </tr>
              <tr>
                <td><div style="position:absolute;left:30%;"><el-button style="height:36px;margin-left: 10px" type="primary"  @click="submitForm('ruleForm')">提交</el-button><el-button style="height:36px;margin-left: 10px" type="info" @click="resetForm('ruleForm')">重置</el-button></div></td>
              </tr>
            </table>
          </el-form>
        </div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple" style="background:transparent"></div>
      </el-col>
    </el-row>
  </div>
</div>
</template>
<script>
import { Message } from 'element-ui'
export default {
  name: 'single_add_robot_yemian',
  data () {
    return {
      options: [
        {
          value: '财务共享',
          label: '财务共享'
        },
        {
          value: '会易通',
          label: '会易通'
        }, {
          value: '党务通',
          label: '党务通'
        }, {
          value: '人力易通',
          label: '人力易通'
        }, {
          value: '审计通',
          label: '审计通'
        }],
      ruleForm: {
        robot_name: '',
        robot_path: '',
        robot_type: '',
        robot_area: localStorage.getItem('yun_user')
      },
      // eslint-disable-next-line vue/no-dupe-keys
      // Visible: true,
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ]
      }
      // aa: false,
      // eslint-disable-next-line vue/no-dupe-keys
    }
  },
  methods: {
    submitForm (formName) {
      const data = this.ruleForm
      this.axios.post('/Robot_Management/', {
        data
      })
        .then(res => {
          // console.log(res.data)
          if (res.data === 1) {
            Message.success('增加成功')
            // this.tableData = res.data.data
            console.log(res.data)
            this.$refs[formName].resetFields()
          } else {
            Message.warning('重复账号')
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetForm (formName) {
      console.log('reset!')
      this.ruleForm.robot_type = ''
      this.ruleForm.robot_name = ''

      // this.$refs[formName].resetFields()
      // this.$refs[formName].resetFields()
      // this.$refs.resetFields()
    }
  }
}
</script>

<style scoped>
#sigle_add_robot {
  margin-top: 30px;
}
h5 {
  line-height: 1px;
  color: #0e0d0d;
  font-size: 22px;
}
th {
  border-top: 100cm;

  color: black;
  font-size: 22px;
}

#bitian{
  color: red;
  font-size: 15px;
  height: 2px;
  background: #0a0a0a;
  top: 105px;

  /*top: 1000px;*/
  /*border-right: 3000cm;*/
  /*border-left: 10000px ;*/
}
</style>
